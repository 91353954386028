const Permissions = (permissions) => {
    let isAllow = false;
    if(localStorage.getItem("loggedInUser")  ==null){
      return false;
    }
    let permission = JSON.parse(localStorage.getItem("loggedInUser") || "");
    let allPermissions = permission?.state?.userData?.data?.responseData?.userData?.permissions || [];
    if (allPermissions.length) {
      allPermissions = allPermissions[0];
    }
    if (permissions) {
      isAllow = false;
      permissions.map((v) => {
        let per = v.includes("&&"); 
        if (per) {
          let andPer = v.split("&&");
          let count = 0;
          andPer.map((vv) => {
            let isPer = allPermissions.includes(vv);
            if (isPer) {
              count++;
            }
          });
          if (andPer.length == count) {
            isAllow = andPer.length == count;
          }
        } else {
          let isPer = allPermissions.includes(v);
          if (isPer) {
            isAllow = isPer;
          }
        }
      });
    }
  
    return isAllow;
  };
  
  export default Permissions;
